<template>
    <div>
        <v-overlay :value="overlay"><v-progress-circular :size="70" :width="7" color="green"
                indeterminate></v-progress-circular></v-overlay>

        <v-container fluid class="px-6 py-6">
            <v-row class="mt-0">
                <v-col cols="12">
                    <div>
                        <v-card class="card-shadow border-radius-xl">
                            <div class="card-header-padding">
                                <div class="d-flex align-center">
                                    <div>
                                        <h5 class="font-weight-bold text-h5 text-typo mb-0">
                                            Previous Purchase Requests
                                        </h5>
                                        <p class="text-sm text-body mb-0"></p>
                                    </div>

                                    <!-- <spacer></spacer> -->
                                    <v-btn elevation="0" dark :ripple="false" height="43" @click="downloadRequest()"
                                        class=" font-weight-bold text-capitalize ms-auto btn-primary bg-success py-3 px-6 ms-3 ">Download
                                    </v-btn>
                                    <v-dialog v-model="dialog" max-width="850px" persistent>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn v-bind="attrs" v-on="on" elevation="0" dark :ripple="false" height="43"
                                                class=" font-weight-bold text-capitalize btn-primary bg-success py-3 px-6 ms-3 ">New</v-btn>
                                        </template>
                                        <v-card class="card-shadow border-radius-xl">
                                            <div class="card-header-padding card-border-bottom">
                                                <span class="font-weight-bold text-h5 text-typo mb-0">
                                                    New Purchase Request
                                                </span>
                                            </div>
                                            <div class="mx-5 mb-5">
                                                <v-form ref="frm">
                                                    <v-row>
                                                        <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                                                            <label class="text-md text-typo font-weight-bolder ms-1">
                                                                <span class="red--text"><strong>*
                                                                    </strong></span>Blend</label>
                                                            <v-select :items="blend_names" item-text="blend" return-object
                                                                hide-details="auto" v-model="blend_name"
                                                                class=" input-style font-size-input text-light-input placeholder-light border-radius-md select-style mt-2 "
                                                                outlined height="46" single-line
                                                                :rules="[(v) => !!v || 'Blend Type is required']"
                                                                placeholder="Select Blend Type">
                                                            </v-select>
                                                        </v-col>

                                                        <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                                                            <label class="text-md text-typo font-weight-bolder ms-1">
                                                                <span class="red--text"><strong>* </strong></span>Quantity
                                                                (Kg)</label>
                                                            <v-text-field v-model="quantity" hide-details="auto"
                                                                class=" input-style font-size-input text-light-input placeholder-light input-icon mt-2 "
                                                                dense flat filled solo height="46" single-line :rules="[(v) =>
                                                                    (!isNaN(parseFloat(v)) && v > 0) ||
                                                                    'Price should be a number greater than 0',
                                                                ]" placeholder="Enter Quantity"></v-text-field>
                                                        </v-col>

                                                        <v-col cols="12" sm="6" md="3" xs="12" lg="3" align-self="center">
                                                            <v-btn @click="newRequestCalculation()" elevation="0"
                                                                :ripple="false" height="46" dark
                                                                class=" font-weight-bold text-capitalize btn-ls btn-primary bg-success px-6 mt-7 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    ">Calculate</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-form>
                                            </div>
                                            <v-card-text class="card-padding">
                                                <v-container class="px-0">
                                                    <v-form>
                                                        <v-data-table :headers="newRequestHeaders" :items="newRequest">
                                                            <template v-slot:[`item.totalQty`]="{ item }">
                                                                <span>
                                                                    {{ formatNumber(item.totalQty) }}
                                                                </span>
                                                            </template>
                                                            <template v-slot:[`item.stock`]="{ item }">
                                                                <span>
                                                                    {{ formatNumber(item.stock) }}
                                                                </span>
                                                            </template>
                                                            <template v-slot:[`item.requiredQty`]="{ item }">
                                                                <span>
                                                                    {{ formatNumber(item.requiredQty) }}
                                                                </span>
                                                            </template>
                                                        </v-data-table>
                                                    </v-form>
                                                </v-container>
                                            </v-card-text>

                                            <v-card-actions class="card-padding d-flex justify-end">
                                                <v-btn @click="close()" elevation="0" :ripple="false" height="43"
                                                    class=" font-weight-bold text-capitalize btn-ls btn-secondary bg-light py-3 px-6 ">Close</v-btn>
                                                <v-btn @click="save()" elevation="0" :ripple="false" height="43" dark
                                                    class=" text-capitalize btn-ls btn-primary bg-success py-3 px-6 ">Save</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </div>
                            </div>

                            <v-card-text class="px-0 py-0">
                                <v-data-table :headers="requestsHeaders" :items="requests">
                                    <template v-slot:[`item.created_date`]="{ item }">
                                        <span>
                                            {{ formatDate(item.created_date) }}
                                        </span>
                                    </template>
                                    <template v-slot:item.checkbox="{ item }">
                                        <v-simple-checkbox v-model="item.checkbox" :ripple="false"
                                            :disabled="item.status != 'APPROVE'"></v-simple-checkbox>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-btn @click="viewRequestDetail(item.id)" icon elevation="0" :ripple="false"
                                            height="28" min-width="36" width="36" class="" color="green">View
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>

                        <v-dialog v-model="dialogRequestDetails" max-width="850px" persistent>
                            <v-card class="card-shadow border-radius-xl">
                                <div class="card-header-padding">
                                    <div class="d-flex align-center">
                                        <div>
                                            <h5 class="font-weight-bold text-h5 text-typo mb-0">
                                                Purchase Request Details
                                            </h5>
                                            <p class="text-sm text-body mb-0"></p>
                                        </div>
                                        <v-spacer></v-spacer>
                                        <action-button :actions="currentPage.actions_allowed" @onClick="allowedAction" />
                                    </div>
                                </div>

                                <v-card-text class="px-0 py-0">
                                    <v-data-table :headers="requestDetailHeaders" :items="requestDetail">
                                        <template v-slot:[`item.totalQty`]="{ item }">
                                            <span>
                                                {{ formatNumber(item.totalQty) }}
                                            </span>
                                        </template>
                                        <template v-slot:[`item.stock`]="{ item }">
                                            <span>
                                                {{ formatNumber(item.stock) }}
                                            </span>
                                        </template>
                                        <template v-slot:[`item.requiredQty`]="{ item }">
                                            <span>
                                                {{ formatNumber(item.requiredQty) }}
                                            </span>
                                        </template>
                                    </v-data-table>
                                </v-card-text>

                                <v-card-actions class="card-padding d-flex justify-end">
                                    <v-spacer></v-spacer>
                                    <v-btn @click="closeDetail" elevation="0" :ripple="false" height="43"
                                        class=" font-weight-bold text-capitalize btn-ls bg-light py-3 px-6 ">Close</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
  
<script>

import api from "../api";
import { mapGetters } from "vuex";
import ActionButton from "../../../../components/ActionButton.vue";
import { json2excel } from "js2excel";

export default {
    components: { ActionButton },
    created() {
        this.initialize();
    },
    data() {
        return {
            overlay: false,
            dialog: false,
            dialogRequestDetails: false,
            blend_names: [],
            blend_name: {
                id: 0,
                blend: '',
                // ratio: '',
                ratioId: 0
            },
            quantity: 0,
            newRequest: [{
                id: 0,
                item_name: '',
                percentage: 0,
                totalQty: 0,
                stock: 0,
                requiredQty: 0
            }],
            requests: [{
                id: 0,
                created_date: null,
                total_qty: 0,
                status: '',
                checkbox: false,
                checkbox_status: false
            }],
            requestDetail: [{
                requestId: 0,
                totalQty: 0,
                item_name: '',
                stock: 0,
                grade: '',
                material: '',
                tea_type: '',
                requiredQty: 0
            }],
            newRequestHeaders: [
                { text: "Name", width: "100", value: "item_name" },
                { text: "Percentage", width: "100", value: "percentage" },
                { text: "Total Quantity (Kg)", width: "150", value: "totalQty" },
                { text: "In Stock (Kg)", width: "100", value: "stock" },
                { text: "Required Quantity (Kg)", width: "150", value: "requiredQty" },
            ],
            requestsHeaders: [
                { text: "Select", width: "50", value: "checkbox" },
                { text: "Blend", width: "150", value: "blend" },
                { text: "Date", width: "100", value: "created_date" },
                { text: "Quantity", width: "100", value: "total_qty" },
                { text: "Status", width: "100", value: "status" },
                { text: "Actions", width: "50", value: "actions" },
            ],
            requestDetailHeaders: [
                { text: "Name", width: "150", value: "item_name" },
                { text: "In Stock (Kg)", width: "150", value: "stock" },
                // { text: "Grade", width: "150", value: "grade" },
                // { text: "Material", width: "150", value: "material" },
                { text: "Required Quantity (Kg)", width: "150", value: "requiredQty" },
            ],
        };
    },
    methods: {
        async initialize() {
            this.overlay = true;
            this.initializeNewRequestTable();
            this.initializeRequestsTable();
            this.initializeRequestDetailTable();
            this.blend_names = await api.getBlendNames();
            this.loadRequest();
            this.overlay = false;
        },
        validate() {
            return this.$refs.frm.validate();
        },
        async newRequestCalculation() {
            if (this.validate()) {
                this.initializeNewRequestTable();
                this.newRequest = await api.getRatioStock(this.blend_name.id);
                this.newRequest.map((r) => {
                    r.totalQty = this.quantity * r.percentage / 100;
                    if (r.totalQty < r.stock) { r.requiredQty = 0; }
                    else { r.requiredQty = r.totalQty - r.stock; }
                });
                //console.log('newRequest', this.newRequest);
            } else {
            }
        },
        async close() {
            this.initializeNewRequestTable();
            this.quantity = 0;
            this.dialog = false;
        },
        async save() {
            if (this.newRequest.length > 0) {
                let purchaseRequestChildArray = [];
                this.newRequest.forEach(async (el) => {
                    purchaseRequestChildArray.push({
                        item_name: el.item_name,
                        net_kgs: el.stock,
                        required_net_kgs: el.requiredQty
                    });
                });
                let purchaseRequest = {
                    blend_ratio_id: this.newRequest[0].id,
                    total_qty: this.quantity,
                    purchaseRequestChild: purchaseRequestChildArray,
                    status: 'PENDING',
                };

                await api.createPurchaseRequest(purchaseRequest)
                    .then(() => {
                        this.showSuccessAlert("Data saved successfully");
                    })
                    .catch((err) => {
                        //this.showErrorAlert(err);
                        throw new Error(err.response.data.message);
                    });
            } else { this.showWarningAlert("No item to save"); }
            await this.loadRequest();
            this.close();
        },
        async loadRequest() {
            this.initializeRequestsTable();
            this.requests = await api.getRequests();
            console.log(this.requests)
        },
        async viewRequestDetail(id) {
            this.initializeRequestDetailTable();
            this.requestDetail = await api.getRequestDetails(id);
            this.dialogRequestDetails = true;
        },
        async downloadRequest() {
            const selectedRequests = this.requests.filter(rq => rq.checkbox === true);
            let requestIds = selectedRequests.map(rq => rq.id);
            if (requestIds.length > 0) {
                this.overlay = true;
                await api.downloadPurchaseRequest(requestIds);
                this.overlay = false;
            }
        },
        async allowedAction(item) {
            this.overlay = true;
            try {
                let purchaseRequestMasterId = this.requestDetail[0].requestId;
                api.allowedActionPurchaseRequest({
                    id: purchaseRequestMasterId,
                    action: item,
                });

                this.overlay = false;
                this.showSuccessAlert("State updated");
                await this.loadRequest();
                this.closeDetail();
            } catch (error) {
                this.showErrorAlert(error);
            }
        },
        closeDetail() {
            this.dialogRequestDetails = false;
        },

        initializeNewRequestTable() {
            this.newRequest = [{
                id: 0,
                item_name: '',
                percentage: 0,
                totalQty: 0,
                stock: 0,
                requiredQty: 0
            }];
        },
        initializeRequestsTable() {
            this.requests = [{
                id: 0,
                created_date: null,
                total_qty: 0,
                status: '',
                checkbox: false,
                checkbox_status: false
            }];
        },
        initializeRequestDetailTable() {
            this.requestDetail = [{
                requestId: 0,
                totalQty: 0,
                item_name: '',
                stock: 0,
                grade: '',
                material: '',
                tea_type: '',
                requiredQty: 0
            }];
        },
        formatNumber(number) {
            return parseFloat(number).toFixed(2);
        },
        formatDate(date) {
            var d = new Date(date),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();
            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;
            return [year, month, day].join("-");
        },
        showWarningAlert(message) {
            this.$swal({
                title: "warning",
                text: message,
                showConfirmButton: false,
                type: "warning",
                timer: 3000,
                icon: "warning",
                showConfirmButton: false,
            });
        },
        showSuccessAlert(message) {
            this.$swal({
                title: "Success",
                text: message,
                type: "success",
                timer: 3000,
                icon: "success",
                showConfirmButton: false,
            });
        },
        showErrorAlert(message) {
            this.$swal({
                title: "Error",
                text: message,
                type: "error",

                icon: "error",
                showConfirmButton: true,
            });
        },
    },

    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDetail(val) {
            val || this.closeDetail();
        },
    },

    computed: {
        ...mapGetters({
            currentPage: "getCurrentPage",
        }),
    },
};
</script>
  
<style></style>
  