var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"green","indeterminate":""}})],1),_c('v-container',{staticClass:"px-6 py-6",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('v-card',{staticClass:"card-shadow border-radius-xl"},[_c('div',{staticClass:"card-header-padding"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',[_c('h5',{staticClass:"font-weight-bold text-h5 text-typo mb-0"},[_vm._v(" Previous Purchase Requests ")]),_c('p',{staticClass:"text-sm text-body mb-0"})]),_c('v-btn',{staticClass:" font-weight-bold text-capitalize ms-auto btn-primary bg-success py-3 px-6 ms-3 ",attrs:{"elevation":"0","dark":"","ripple":false,"height":"43"},on:{"click":function($event){return _vm.downloadRequest()}}},[_vm._v("Download ")]),_c('v-dialog',{attrs:{"max-width":"850px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:" font-weight-bold text-capitalize btn-primary bg-success py-3 px-6 ms-3 ",attrs:{"elevation":"0","dark":"","ripple":false,"height":"43"}},'v-btn',attrs,false),on),[_vm._v("New")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"card-shadow border-radius-xl"},[_c('div',{staticClass:"card-header-padding card-border-bottom"},[_c('span',{staticClass:"font-weight-bold text-h5 text-typo mb-0"},[_vm._v(" New Purchase Request ")])]),_c('div',{staticClass:"mx-5 mb-5"},[_c('v-form',{ref:"frm"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","xs":"12","lg":"3"}},[_c('label',{staticClass:"text-md text-typo font-weight-bolder ms-1"},[_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v("Blend")]),_c('v-select',{staticClass:" input-style font-size-input text-light-input placeholder-light border-radius-md select-style mt-2 ",attrs:{"items":_vm.blend_names,"item-text":"blend","return-object":"","hide-details":"auto","outlined":"","height":"46","single-line":"","rules":[function (v) { return !!v || 'Blend Type is required'; }],"placeholder":"Select Blend Type"},model:{value:(_vm.blend_name),callback:function ($$v) {_vm.blend_name=$$v},expression:"blend_name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","xs":"12","lg":"3"}},[_c('label',{staticClass:"text-md text-typo font-weight-bolder ms-1"},[_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v("Quantity (Kg)")]),_c('v-text-field',{staticClass:" input-style font-size-input text-light-input placeholder-light input-icon mt-2 ",attrs:{"hide-details":"auto","dense":"","flat":"","filled":"","solo":"","height":"46","single-line":"","rules":[function (v) { return (!isNaN(parseFloat(v)) && v > 0) ||
                                                                'Price should be a number greater than 0'; } ],"placeholder":"Enter Quantity"},model:{value:(_vm.quantity),callback:function ($$v) {_vm.quantity=$$v},expression:"quantity"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","xs":"12","lg":"3","align-self":"center"}},[_c('v-btn',{staticClass:" font-weight-bold text-capitalize btn-ls btn-primary bg-success px-6 mt-7 \n                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                ",attrs:{"elevation":"0","ripple":false,"height":"46","dark":""},on:{"click":function($event){return _vm.newRequestCalculation()}}},[_vm._v("Calculate")])],1)],1)],1)],1),_c('v-card-text',{staticClass:"card-padding"},[_c('v-container',{staticClass:"px-0"},[_c('v-form',[_c('v-data-table',{attrs:{"headers":_vm.newRequestHeaders,"items":_vm.newRequest},scopedSlots:_vm._u([{key:"item.totalQty",fn:function(ref){
                                                            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatNumber(item.totalQty))+" ")])]}},{key:"item.stock",fn:function(ref){
                                                            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatNumber(item.stock))+" ")])]}},{key:"item.requiredQty",fn:function(ref){
                                                            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatNumber(item.requiredQty))+" ")])]}}],null,true)})],1)],1)],1),_c('v-card-actions',{staticClass:"card-padding d-flex justify-end"},[_c('v-btn',{staticClass:" font-weight-bold text-capitalize btn-ls btn-secondary bg-light py-3 px-6 ",attrs:{"elevation":"0","ripple":false,"height":"43"},on:{"click":function($event){return _vm.close()}}},[_vm._v("Close")]),_c('v-btn',{staticClass:" text-capitalize btn-ls btn-primary bg-success py-3 px-6 ",attrs:{"elevation":"0","ripple":false,"height":"43","dark":""},on:{"click":function($event){return _vm.save()}}},[_vm._v("Save")])],1)],1)],1)],1)]),_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-data-table',{attrs:{"headers":_vm.requestsHeaders,"items":_vm.requests},scopedSlots:_vm._u([{key:"item.created_date",fn:function(ref){
                                                            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.created_date))+" ")])]}},{key:"item.checkbox",fn:function(ref){
                                                            var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"ripple":false,"disabled":item.status != 'APPROVE'},model:{value:(item.checkbox),callback:function ($$v) {_vm.$set(item, "checkbox", $$v)},expression:"item.checkbox"}})]}},{key:"item.actions",fn:function(ref){
                                                            var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","elevation":"0","ripple":false,"height":"28","min-width":"36","width":"36","color":"green"},on:{"click":function($event){return _vm.viewRequestDetail(item.id)}}},[_vm._v("View ")])]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"max-width":"850px","persistent":""},model:{value:(_vm.dialogRequestDetails),callback:function ($$v) {_vm.dialogRequestDetails=$$v},expression:"dialogRequestDetails"}},[_c('v-card',{staticClass:"card-shadow border-radius-xl"},[_c('div',{staticClass:"card-header-padding"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',[_c('h5',{staticClass:"font-weight-bold text-h5 text-typo mb-0"},[_vm._v(" Purchase Request Details ")]),_c('p',{staticClass:"text-sm text-body mb-0"})]),_c('v-spacer'),_c('action-button',{attrs:{"actions":_vm.currentPage.actions_allowed},on:{"onClick":_vm.allowedAction}})],1)]),_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-data-table',{attrs:{"headers":_vm.requestDetailHeaders,"items":_vm.requestDetail},scopedSlots:_vm._u([{key:"item.totalQty",fn:function(ref){
                                                            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatNumber(item.totalQty))+" ")])]}},{key:"item.stock",fn:function(ref){
                                                            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatNumber(item.stock))+" ")])]}},{key:"item.requiredQty",fn:function(ref){
                                                            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatNumber(item.requiredQty))+" ")])]}}],null,true)})],1),_c('v-card-actions',{staticClass:"card-padding d-flex justify-end"},[_c('v-spacer'),_c('v-btn',{staticClass:" font-weight-bold text-capitalize btn-ls bg-light py-3 px-6 ",attrs:{"elevation":"0","ripple":false,"height":"43"},on:{"click":_vm.closeDetail}},[_vm._v("Close")]),_c('v-spacer')],1)],1)],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }